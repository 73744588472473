import { PaymentAccount } from "./payment_account";

export class Profile {
    id: number;
    name: string;
    email: string;
    birthday: string;
    status: string
    phone: string;
    role: string
    cpf: string;
    photo: string;  // should be an URL
    billingStatus: string;
    userId:number
    mkup: number
    paymentAccount: PaymentAccount
}
