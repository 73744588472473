import { Component, OnInit } from '@angular/core';
import { TermsPolicy } from '../model/terms_policy';
import { DashboardService } from '../services/dashboard.service';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.scss']
})
export class TermsPolicyComponent implements OnInit {

  public termsPolicy:TermsPolicy


  constructor(
    public snack: MatSnackBar,
    private dashboardService: DashboardService
  ) { 
    this.termsPolicy = new TermsPolicy()
   
  }

  ngOnInit() {
this.getTermsPolicy()
   
  }

  getTermsPolicy(){
    this.dashboardService.getTermsAndPolicy().toPromise().then((value)=>{
      this.termsPolicy = value
      
    });
  }
  update(){
   this.dashboardService.updateTermsAndPolicy(this.termsPolicy).toPromise().then(async(val)=>{
   this.getTermsPolicy()
    this.snack.open("Termos e Políticas atualizados",null,{duration:3000, })
   },(re)=>{
    this.snack.open("Não foi possível atualizar os termos e políticas",null,{duration:3000})
   })
  }
}
