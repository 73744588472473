import { Component, Inject } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Plug } from 'src/app/model/plug';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-modal-connectors',
  templateUrl: './modal-connectors.component.html',
  styleUrls: ['./modal-connectors.component.scss']
})
export class ModalConnectorsComponent  {
  public publicData = { conn: null, action: null };
  public conn: Plug;
  title = 'Novo conector';

  private ref: AngularFireStorageReference;
  private task: AngularFireUploadTask;
  public uploadProgress: Observable<string>;
  public downloadURL: string;
  public uploading: boolean = false

  

  constructor(
    private afStorage: AngularFireStorage,
    public dialogRef: MatDialogRef<ModalConnectorsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    Object.assign(this.publicData, data);


    if (data.action === 'edit') {
      this.conn = data.conn;
      this.title = "Editar Conector";
    } else {

      this.conn = new Plug();
    this.conn.type = ""
    }
  }

  upload(event) {

    const randomId = "connector"+(new Date().getTime()*10000);
    this.ref = this.afStorage.ref("/connectors/"+randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploading = true
    this.uploadProgress = this.task.percentageChanges().pipe(map((v)=>v.toFixed(0)));
    this.task.then(async(file) => {
      this.conn.image = await file.ref.getDownloadURL()
      this.uploading = false
      //this.conn.id = (isNullOrUndefined(this.conn.id) || this.conn.id.toString() === "") ? randomId : this.conn.id;
    
    });
  }


  save(): void {
    this.dialogRef.close(this.conn);
  }

  closeClick(): void {
    this.dialogRef.close();
  }

}
