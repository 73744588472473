export class Pagination{
  
   
    total:number= 0
    page:number = 0
    maxPage:number
    size: number = 25

}

