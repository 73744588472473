import * as admin from 'firebase';
import { Iconbox } from './iconbox';
import { Profile } from './profile';
import { User } from './user';
import { Address } from './address';

export class Condominium {
    name: string;
    id: number
    devices: Iconbox[];
    profiles: Profile[]
    lat:number;
    lng: number
    iss:number
    
    responsable: string;
    email: string;
    phone: string;

    address:Address
    
}
