import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/model/client';
import { Billingstatus } from 'src/app/model/billingstatus.enum';
import { UserService } from 'src/app/services/user.service';
import { Iconbox } from 'src/app/model/iconbox';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Profile } from 'src/app/model/profile';
import { Key } from 'src/app/model/key';
import { ChargeService } from 'src/app/services/charge.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-modal-user-key',
  templateUrl: './modal-user-key.component.html',
  styleUrls: ['./modal-user-key.component.scss']
})
export class ModalUserKeyComponent implements OnInit {
  public publicDataUsers = { client: null, action: null };
  public title = '';
  public sub = '';
  public client: Profile;
  newKey: string
  public statusList: any[];
  keys: Key[]
  selectedCharger: Iconbox;
  selectedKey: Key;
  chargers: Iconbox[]

  status = Billingstatus;


  constructor(
    public snack:MatSnackBar,
    public dialogRef: MatDialogRef<ModalUserKeyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: DashboardService, private chargerService: ChargeService) {
    Object.assign(this.publicDataUsers, data);

    this.newKey = ""
    this.client = data.user;
    this.title = this.client.name + " - " + "Vincular Equipamento";


  }
  ngOnInit(): void {
    this.getKeys()
    this.getDevices()
  }

  public getKeys() {
    this.service.getKeysByProfile(this.client.id).subscribe((value) => {
      this.keys = value
    })
  }

  public getDevices() {
    this.chargerService.getChargersByUser(this.client.id).subscribe((value) => {
      console.log(value)
      this.chargers = value
    })
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  chargersChange(charges: Iconbox[]) {

    this.selectedCharger = charges[0];
    console.log(this.selectedCharger)
  }

  keyChange(keys: Key[]) {
    this.selectedKey = keys[0];
    console.log(this.selectedKey)
  }

  connectKeyDevice() {
  
    if (this.selectedCharger != null && this.selectedKey != null) {
      this.service.connectKeyDevice({ keyId: this.selectedKey.id, deviceId: this.selectedCharger.id }).subscribe((value) => {
        if (value) {
          this.getDevices()
        }
      })
    }else{
      this.snack.open("Selecione a chave e o equipamento antes de prosseguir" )
    }
  }

  disconnectKey(index: number) {
    var device = this.chargers[index]
    this.service.disconnectKeyDevice({ deviceId: device.id, keyId: device.keys[0].id }).subscribe((value) => {
      if (value) {
        this.getDevices()
      }
    })
  }




}
