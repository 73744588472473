import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { Condominium } from '../model/condominium';
import { environment } from 'src/environments/environment';
import { ResponseData } from '../model/responsedata';
import { map } from 'rxjs/operators';
import baseUrl from './util';
import { CondominiumProfile } from '../model/condominium_user';
import { CondominiumIconbox } from '../model/condominium_charger';
import { Profile } from '../model/profile';
import { User } from '../model/user';
import { Iconbox } from '../model/iconbox';
import { CepAddress } from '../model/cep_address';

@Injectable({
  providedIn: 'root'
})
export class CondominiumService {
  private _ENDPOINT = 'condominiuns';

  constructor(private http: HttpClient) { }

  private getHeaders() {
    return {
      'x-access-token': localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
  }

  public getAll(): Observable<Condominium[]> {
    return this.http.get(baseUrl+"/condominium" )
      .pipe(map((response: ResponseData<Condominium>) => response.data));
  }

  public add(cond: Condominium): Observable<Condominium> {
    return this.http.post(baseUrl+"/condominium", cond)
      .pipe(map((response: Condominium) => response));
  }

  public addProfile(condominiumId: number, profileId: number): Observable<Profile[]> {
    return this.http.post(baseUrl+"/condominium/user",{condominiumId, profileId})
      .pipe(map((response: Profile[]) => response));
  }

  public removeProfile(cond: CondominiumProfile): Observable<Profile[]> {
    return this.http.post(baseUrl+"/condominium/user/remove", cond)
      .pipe(map((response: Profile[]) =>response));
  }

  public addCharger(cond: CondominiumIconbox): Observable<Iconbox[]> {
    return this.http.post(baseUrl+"/condominium/icombox", cond)
      .pipe(map((response: Iconbox[]) => response));
  }

  public removeCharger(cond: CondominiumIconbox): Observable<Iconbox[]> {
    return this.http.post(baseUrl+"/condominium/icombox/remove", cond)
      .pipe(map((response: Iconbox[]) =>response));
  }

  public edit(condo: Condominium): Observable<Condominium> {
    return this.http.patch(baseUrl+"/condominium/"+condo.id, condo, )
      .pipe(map((response: Condominium) => response));
  }

  public del(id: number): Observable<boolean> {
    return this.http.delete(baseUrl+"/condominium/"+id, )
      .pipe(map((response: any) => response.data as boolean));
  }

  public getCep(cep: string): Observable<CepAddress> {
    return this.http.get("https://viacep.com.br/ws/"+cep+"/json/" )
      .pipe(map((response: CepAddress) => response));
  }
}
