import { Address } from "./address"
import { ChargeStation } from "./charge-station"

export class ChargePoint{

  id: number


  type:string

  name:string

  photo:string

  phone: string


  opening_hours:string


  partner: string


  parking_pay: string


  pay_type: string


  description:string


  extra: string


  stations: ChargeStation[]


  address:Address
}
