import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ChargeService } from 'src/app/services/charge.service';
import { Iconbox } from 'src/app/model/iconbox';
import { MatDialog } from '@angular/material/dialog';
import { ModalChargersComponent } from '../modal-chargers/modal-chargers.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Chargerstatus } from 'src/app/model/chargerstatus.enum';
import { ModalChargeKeyComponent } from '../modal-charge-key/modal-charge-key.component';


@Component({
  selector: 'app-charge-list',
  templateUrl: './charge-list.component.html',
  styleUrls: ['./charge-list.component.scss']
})
export class ChargeListComponent implements OnInit,OnDestroy {

  @Input() dashboard: boolean;
  id:any
  chargerList: Iconbox[];
  Chargerstatus = Chargerstatus;
  
  constructor(
    private service: ChargeService,
    private dialog: MatDialog,
    
    private dashboardService: DashboardService
  ) { 
    
   
  }

  ngOnInit() {
    this.getChargers();
    
    this.id = setInterval(()=> this.getChargers(false),3000)
  }

  ngOnDestroy(): void {
   clearInterval(this.id)
  }

  getChargers(load?:boolean) {
   
      this.dashboardService.getChargers(load).subscribe(chargers => {
        this.chargerList = chargers;
        this.chargerList.sort((a,b)=>a.name.localeCompare(b.name))
      });
    
  }

  newCharge() {
    const dialogRef = this.dialog.open(ModalChargersComponent, {
      width: '50em',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.addCharger(result).subscribe(addes => {
          this.getChargers();
        });
      }
    });
  }
  viewKeys() {
    const dialogRef = this.dialog.open(ModalChargeKeyComponent, {
      width: '50em',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }

  del(charger: Iconbox) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      width: '400px',
      data: {
        type: 'question',
        message: 'Esta ação irá deletar equipamento selecionado.<br/> Deseja continuar?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.delCharger(charger.id).subscribe(() => {
          this.getChargers();
        });
      }
    });
  }
  
  edit(chg: Iconbox) {
    const dialogRef = this.dialog.open(ModalChargersComponent, {
      width: '50em',
      data: { charger: chg, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.editCharger(result).subscribe(() => {
          this.getChargers();
        });
      }else{
      this.getChargers();
      }
    });
  }

  // changeStatus(charger: Charger) {
  //   if (charger.chargerstatus === Chargerstatus.CONNECTED) {
  //     this.service.changeStatus(charger, Chargerstatus.OFFLINE).subscribe(() => {
  //       this.getChargers();
  //     }, () => this.getChargers());
  //   } else {
  //     this.service.changeStatus(charger, Chargerstatus.ERROR).subscribe(() => {
  //       this.getChargers();
  //     }, () => this.getChargers());
  //   }
  // }

}
