import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChargePoint } from '../model/charge-point';
import baseUrl from './util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChargeStation } from '../model/charge-station';

@Injectable({
  providedIn: 'root'
})
export class ChargePointService {


  constructor(private http: HttpClient) { }

  updateChargePoint(point: ChargePoint): Observable<ChargePoint>{
    return this.http.patch(baseUrl+'/charge-point/'+point.id,point).pipe<ChargePoint>(map((r:any)=>r))
  }

  createChargePoint(point: ChargePoint): Observable<ChargePoint>{
    return this.http.post(baseUrl+'/charge-point',point).pipe<ChargePoint>(map((r:ChargePoint)=>r))
  }

  findOne(id:number){
    return this.http.get(baseUrl+'/charge-point/'+id)
  }

  findAll(){
    return this.http.get(baseUrl+'/charge-point/')
  }

  delete(id:number){
    return this.http.delete(baseUrl+'/charge-point/'+id)
  }

  findStations(pointId:number){
    return this.http.get(baseUrl+'/charge-point/'+pointId+"/stations")
  }
  addStation(pointId: number,station: { power: any; type: any; }):Observable<ChargeStation> {
    return this.http.post(baseUrl+'/charge-point/'+pointId+"/stations", station).pipe(map((data: ChargeStation)=>data))
  }
  removeStation(id:number){
    return this.http.delete(baseUrl+'/charge-point/stations/'+id)
  }


}
