import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { Client } from '../model/client';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ResponseData } from '../model/responsedata';
import { VehicleModel } from '../model/vehicle_model';
import baseUrl from './util';
import { ConnectorVehicle } from '../model/connector_vehicle';
import { Plug } from '../model/plug';
import { VehicleTypeEnum } from '../model/vehicle-type.enum';
import { VehicleBrand } from '../model/vehicle_brand';

@Injectable({
  providedIn: 'root'
})

export class VehiclesService {
 

  private _ENDPOINT = 'vehicles';


  constructor(private http: HttpClient) { }

  private getHeaders() {
    return {
      'x-access-token': localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
  }
  public getVehicles(page:number, size: number): Observable<ResponseData<VehicleModel>> {
    return this.http.get(baseUrl+`/vehicle/model?page=${page}&size=${size}`, { headers: this.getHeaders() })
      .pipe(map((response: ResponseData<VehicleModel>) => response));
  }

  public addVehicle(pVehicle: VehicleModel): Observable<VehicleModel> {
    return this.http.post(baseUrl+"/vehicle/model", pVehicle, )
      .pipe(map((response: VehicleModel) =>response));
  }

  public editVehicle(pVehicle: VehicleModel): Observable<VehicleModel> {
    return this.http.patch(baseUrl+"/vehicle/model/"+pVehicle.id,pVehicle)
      .pipe(map((response: VehicleModel) => response));
  }

  public delVehicle(id: number): Observable<boolean> {
    return this.http.delete(baseUrl+"/vehicle/model/"+id)
      .pipe(map((response: any) => response as boolean));
  }

  getBrands():Observable<VehicleBrand[]> {
    return this.http.get(baseUrl+"/vehicle/brands/")
    .pipe(map((response: VehicleBrand[]) => response));
  }

  public addConnectorCharger(cc: ConnectorVehicle): Observable<Plug[]> {
   
    return this.http.post(baseUrl+"/vehicle/connector/add", cc).pipe(map((c)=>c as Plug[]));
  }

  public removeConnectorCharger(cc: ConnectorVehicle): Observable<Plug[]> {
  
    return this.http.post(baseUrl+"/vehicle/connector/remove", cc).pipe(map((c)=>c as Plug[]));
  }

}
