import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Charge } from 'src/app/model/charge';
import { Client } from 'src/app/model/client';
import { Profile } from 'src/app/model/profile';
import { Billing } from 'src/app/services/billing';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as XLSX from 'xlsx'

@Component({
  selector: 'app-modal-user-history',
  templateUrl: './modal-user-history.component.html',
  styleUrls: ['./modal-user-history.component.scss']
})
export class ModalUserHistoryComponent implements OnInit {

  chargeHistory: Billing[];
  years:number[]
  client: Profile;
  selectedYear: number

  monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  constructor(
    private dashboardService: DashboardService,
    public dialogRef: MatDialogRef<ModalUserHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.chargeHistory = [];
    this.years = []
    this.client = this.data.user;

    var nowYear = new Date().getFullYear()
    this.selectedYear = nowYear

    for(let i =nowYear-5; i<  nowYear+1; i++)
      this.years.push(i);
    
    this.fetchCharges();
    this.processData();
  }

  processData() {
    this.chargeHistory = this.chargeHistory.sort((a,b)=>a.openDate < b.openDate?1:-1)

    this.chargeHistory = this.chargeHistory.map((e) => {


      if(moment(e.openDate).month() == moment().month())e.state = "Conta Aberta";
      else {
        var date = moment(e.openDate)

        date.set('month',date.month()+1)
        date.set('date', 0)

        e.closeDate = date.utc().toDate();
        e.state = "Conta Fechada";
      }
      var values = e.charges.map((c) => c.total)
      var value = 0;
      values.forEach((v) => { value += v })

      e.value = value

      var consums = e.charges.map((c) => c.consum)
      var consum = 0;
      consums.forEach((v) => { consum += v })

      e.consum = consum

      e.charges = e.charges.sort((a,b)=>a.chargeInit > b.chargeInit?-1:1)

      return e
    })


  }

  onChange(event){
    this.selectedYear = event.target.value

    this.fetchCharges()
  }

  fetchCharges(){
    this.dashboardService.getUserHistory(this.client.id, this.selectedYear).subscribe((response) => {
      this.chargeHistory = response;
      this.processData()
    });
  }
  closeClick(): void {
    this.dialogRef.close(false);
  }
  async exportCSV(billing: Billing) {
    var data: any = []
    data = await Promise.all(billing.charges.map(async (el:Charge) => {

      var charger = el.device;

      var initDate = el.chargeInit != null ? moment(el.chargeInit) : null
      var endDate = el.chargeEnd != null ? moment(el.chargeEnd) : null

      var response: any[] = [

        //iot
        charger != null ? charger.meterId :el.meterId,//n de serie
        charger != null ? charger.power : el.power,//potencia
        charger != null ? charger.price : el.price != null? el.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }):0,
        //Veiculo
        "",//"Marca":
        "",//"Modelo":
        "",//ano
        "",//"Placa":
        "",//"Pot. Bateria(kWh)"
        //inicio recarga
        initDate != null ? initDate.year().toString() : "",// "Ano":
        initDate != null ? initDate.month() + 1 : "",//"Mês":
        initDate != null ? initDate.date() : "",//"Dia":
        initDate != null ? initDate.format("HH:mm:ss") : "",//"Horário":
        //fim de recarga
        endDate != null ? endDate.year().toString() : "",// "Ano":
        endDate != null ? endDate.month() + 1 : "",//"Mês":
        endDate != null ? endDate.date() : "",//"Dia":
        endDate != null ? endDate.format("HH:mm:ss") : "",//"Horário":
        //Parada
        el.deltaTime,//"Delta Hora":
        //Dados da sessão
        el.chargeTime,//"Tempo Carga (F-I-TP)":
        el.current,//"Corrente Max.(A)":
        el.consum,//"kWh consumido":
        el.total != null? el.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }):0//"Custo da Carga":
      ]

      return response;

    }))

    //   var user = (await firebase.firestore().collection("user").doc("gUCxxOvegZeQabrVmNbEltPqIQf2").get()).data()



    var sheet = XLSX.utils.aoa_to_sheet([
      [
        "CPF",
        "Nome Cliente",
        "Endereço Cobrança",
        "CEP",
        "Cod. TAG",
        "No. Celular",
        "Valor Mensalidade",
        "Imposto ISS %",
      ],

      [
        this.client.cpf,//cpf
        this.client.name,
        "",//endereço
        "",//cep
        "",//TAG
        this.client.phone,//telefone
        this.client.paymentAccount.subscription,//valor mensalidade
        this.client.paymentAccount.iss,//imposto iss
      ],
      [],
      [

        "Dados ponto de recarga", '', '',
        "Dados do VE", '', '', '', '',
        "Inicio de Carga", '', '', '',
        "Fim de Carga", '', '', '',
        "Tempo Parada Acumul.",
        "Dados da Sessão de Recarga",
      ], [

        // "CPF",
        // "Nome Cliente",
        // "Endereço Cobrança",
        // "CEP",
        // "Cod. TAG",
        // "No. Celular",
        // "Valor Mensalidade",
        // "Imposto ISS %",
        //iot
        "No. Série ICombox",
        "Potência do PR(kW)",
        "Preço base kW/h",
        //Veiculo
        "Marca",
        "Modelo",
        "Ano",
        "Placa",
        "Pot. Bateria(kWh)",
        //inicio recarga
        "Ano",
        "Mês",
        "Dia",
        "Horário",
        //fim de recarga
        "Ano",
        "Mês",
        "Dia",
        "Horário",
        //Parada
        "Delta Hora",
        //Dados da sessão
        "Tempo Carga (F-I-TP)",
        "Corrente Max.(A)",
        "kWh consumido",
        "Custo da Carga"


      ], ...data
    ])
    const merge = [
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 2, c: 8 }, e: { r: 2, c: 10 } },
      { s: { r: 2, c: 11 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 19 } },
      { s: { r: 2, c: 20 }, e: { r: 2, c: 23 } },
      //{ s: { r: 0, c: 24 }, e: { r: 0, c: 25 } },
      { s: { r: 0, c: 25 }, e: { r: 0, c: 28 } },
    ];
    sheet["!merges"] = merge;
    var wb = XLSX.utils.book_new()

    const currency = 'R$0.00'
    for (let col of [28]) {
      this.formatColumn(sheet, col, currency)
    }

    XLSX.utils.book_append_sheet(wb, sheet)

    XLSX.writeFileXLSX(wb, "recargas.xlsx")

    //this.download(wb)

}


formatColumn(worksheet, col, fmt) {
  const range = XLSX.utils.decode_range(worksheet['!ref'])
  // note: range.s.r + 1 skips the header row
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: col })
    if (worksheet[ref] && worksheet[ref].t === 'n') {
      worksheet[ref].z = fmt

    }
  }
}

  public exportToCsv(rows: object[], columns ?: string[]): string {
  if (!rows || !rows.length) {
    return;
  }
  const separator = ',';
  const keys = Object.keys(rows[0]).filter(k => {
    if (columns.length) {
      return columns.includes(k);
    } else {
      return true;
    }
  });
  const csvContent =
    keys.join(separator) +
    '\n' +
    rows.map(row => {
      return keys.map(k => {
        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
        cell = cell instanceof Date
          ? cell.toLocaleString()
          : cell.toString().replace(/"/g, '""');
        if (cell.search(/("|,|\n)/g) >= 0) {
          cell = `"${cell}"`;
        }
        return cell;
      }).join(separator);
    }).join('\n');
  const a = document.createElement('a');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = 'recargas.csv';
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

download(data: any) {
  const a = document.createElement('a');
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = 'recargas.csv';
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

}
