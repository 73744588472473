import { Component, OnInit } from '@angular/core';
import { EVSE } from '../model/evse';
import { ChargeService } from '../services/charge.service';
import { MatDialog } from '@angular/material';
import { ModalEvseComponent } from '../components/modal-evse/modal-evse.component';
import { EvseService } from '../services/evse.service';

@Component({
  selector: 'app-evse-list',
  templateUrl: './evse-list.component.html',
  styleUrls: ['./evse-list.component.scss']
})
export class EvseListComponent implements OnInit {

  evseList: EVSE[]
  constructor(
    private dialog: MatDialog,
    private service: EvseService) {
    this.evseList =[]
    
   }

  ngOnInit() {
    this.fetchEvse()
  }

  fetchEvse(){

    this.service.getEvses().subscribe((response)=>{
      this.evseList = response.data
      this.evseList.sort((a,b)=>a.model.localeCompare(b.model))
    })
  }

  
  addEVSE(){
  
    const dialogRef = this.dialog.open(ModalEvseComponent, {
      width: '50em'
    });
    dialogRef.afterClosed().subscribe((v)=>{
      this.fetchEvse()
    })
  }
  editEVSE(evse){
   
    const dialogRef = this.dialog.open(ModalEvseComponent, {
      width: '50em',
      data: evse
    });

    dialogRef.afterClosed().subscribe((v)=>{
      this.fetchEvse()
    })

    
  }
  removeEVSE(evse){
    
    this.service.removeEvse(evse.id).toPromise().then(()=>{
      this.fetchEvse()
    });
    
  }

}
