

export class TermsPolicy{
    terms:string
    policy: string
    requestAccept:boolean
    

    constructor(){
        this.terms = ""
        this.policy = ""
        this.requestAccept = false;
    }
}