import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChargeService } from 'src/app/services/charge.service';
import { Iconbox } from 'src/app/model/iconbox';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-charger-select',
  templateUrl: './charger-select.component.html',
  styleUrls: ['./charger-select.component.scss']
})
export class ChargerSelectComponent implements OnInit {
  chargerList: Iconbox[];
  selectedOption: number;
  @Input() hideButton:boolean
  @Input() selected: Iconbox[];
  @Output() selection: EventEmitter<Iconbox[]> = new EventEmitter<Iconbox[]>();


  constructor(private service: ChargeService) {
  }

  ngOnInit() {
    
    
    this.chargerList = [];
    
    this.getChargers();
  }

  getChargers() {
    this.service.getChargers().subscribe(response => {
      
      this.chargerList = response.data;
    });
  }

  filter(){
    return this.chargerList.filter((e)=>this.selected.filter((b)=>b.id == e.id).length ==0)
  }

  change(){
    this.selection.emit([this.filter()[this.selectedOption]]);
  }
  add() {
    
    
   
    this.selection.emit([this.filter()[this.selectedOption]]);
  

    
  }

  remove(index) {
   // const charger = this.selected.splice(index, 1);
   // this.chargerList.push(charger[0]);
    this.selection.emit([this.selected[index]]);
  }

}
