import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/model/client';
import { Billingstatus } from 'src/app/model/billingstatus.enum';
import { UserService } from 'src/app/services/user.service';
import { Iconbox } from 'src/app/model/iconbox';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Profile } from 'src/app/model/profile';
import { Key } from 'src/app/model/key';
import { User } from 'src/app/model/user';
import { PaymentAccount } from 'src/app/model/payment_account';
import { MatExpansionModule, MatSnackBar } from '@angular/material';
import { Condominium } from 'src/app/model/condominium';
import { ModalAlertDialogComponent } from '../modal-alert-dialog/modal-alert-dialog.component';
import { ModalUserKeyComponent } from '../modal-user-key/modal-user-key.component';

@Component({

  selector: 'app-modal-users',
  templateUrl: './modal-users.component.html',
  styleUrls: ['./modal-users.component.scss']
})
export class ModalUsersComponent implements OnInit {

  public title = '';
  public sub = '';
  public user: User
  public client: Profile;
  public statusList: string[];
  public optionsStatus: {};
  selectedChargers: Iconbox[];
  public paymentAccount: PaymentAccount
  public newKey: string
  public lote: string
  status = Billingstatus;
  keys: Key[]
  condominium: Condominium

  constructor(
    public dialogRef: MatDialogRef<ModalUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private service: DashboardService,
    private userService: UserService) {


    if (data.action == "edit") {
      this.client = { ...data.client };
      this.title = this.client.name || 'Cadastro incompleto';
      this.sub = this.client.phone;
      this.paymentAccount = this.client.paymentAccount
    }

    else {
      this.user = new User()
      this.client = new Profile();
      this.title = "Novo usuário";
      this.sub = ""
      this.paymentAccount = new PaymentAccount()
      this.condominium = data.condominium

    }

    this.optionsStatus = { "active": "Ativo", 'suspended': "Suspenso", 'pending': "Pendente", 'blocked': "Bloqueado" };

    this.statusList = Object.keys(this.optionsStatus)
  }

  ngOnInit(): void {
    this.getKeys()
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  changeMKUP(v: string) {

    if (v.length > 0) {
      v = v.replace("%", "")
      v = v.replace(",", ".")

      v = v.includes('.') ? v.substring(0, 4) : v.substring(0, 3)

      this.client.mkup = Number.parseFloat(v)
    } else {
      this.client.mkup = 0
    }
  }

  chargersChange(charges: Iconbox[]) {
    this.selectedChargers = charges;
  }

  public getKeys() {
    this.service.getKeysByProfile(this.client.id).subscribe((value) => {
      console.log(value)
      this.keys = value
    })
  }

  save(): void {
    var value = this.paymentAccount.subscription;

    if (value == null) value = 0
    this.paymentAccount.subscription = Number.parseFloat(value.toString().replace(",", "."))

    if (this.user == null) {
      this.client.paymentAccount = this.paymentAccount
      this.dialogRef.close(this.client);
    }

    else {


      this.client.paymentAccount = this.paymentAccount
      this.user.cpf = this.client.cpf
      this.user.email = this.client.email
      this.user.profile = this.client

      this.userService.createUser(this.user, this.condominium, { serialNumber: this.newKey, lote: this.lote }).toPromise().then((user) => {
        this.dialogRef.close();
        if (this.newKey != null && this.newKey.length > 0) {
          this.dialog.open(ModalAlertDialogComponent, {
            width: '30em',
            data: {
              message: 'Deseja vincular o usuário a um Icombox?',
              title: "Usuário cadastrado",
              options: [
                { name: "Sim", value: true },
                { name: "Não", value: false }
              ]
            }
          }).afterClosed().toPromise().then((value) => {
            if (value) {
              this.dialog.open(ModalUserKeyComponent, {
                width: '40em',
                data: {
                  user: user.profile
                }
              })
            }
          })
        } else {
          this.snackBar.open('usuário cadastrado', 'ok', { duration: 3000 })

        }


      }, (err) => {
        this.snackBar.open('Não foi possível cadastrar o usuário', 'ok', { duration: 3000 })
      })

    }
  }


  createKey(): void {
    this.service.createKey({ profileId: this.client.id, serialNumber: this.newKey, lote: this.lote }).subscribe((response) => {
      this.newKey = ""
      this.getKeys()
    }, (err) => {
      console.error(err);
    });
  }

  public updateKey(key: Key) {
    key.active = !key.active
    this.service.updateKey(key).subscribe((response) => {

      this.getKeys()
    }, (err) => {
      console.error(err);
    });
  }

  removeKey(index: number): void {
    this.service.removeKey(this.keys[index].id).subscribe((response) => {
      this.newKey = ""
      this.getKeys()
    }, (err) => {
      console.error(err);
    });
  }
}
