import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connector-page',
  templateUrl: './connector-page.component.html',
  styleUrls: ['./connector-page.component.scss']
})
export class ConnectorPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
