import { Component, OnInit } from '@angular/core';
import { CondominiumService } from 'src/app/services/condominium.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalCondominiumComponent } from '../modal-condominium/modal-condominium.component';
import { Condominium } from 'src/app/model/condominium';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { ModalAlertDialogComponent } from '../modal-alert-dialog/modal-alert-dialog.component';
import { ModalUsersComponent } from '../modal-users/modal-users.component';

@Component({
  selector: 'app-condominium-list',
  templateUrl: './condominium-list.component.html',
  styleUrls: ['./condominium-list.component.scss']
})
export class CondominiumListComponent implements OnInit {
  condoList = [];

  constructor(private service: CondominiumService, private dialog: MatDialog) { }

  ngOnInit() {
    this.getCondoms();
  }

  getCondoms() {
    this.service.getAll().subscribe(condos => {
      this.condoList = condos;
     
    });
  }

  add() {

    const dialogRef = this.dialog.open(ModalCondominiumComponent, {
      width: '50em',
      data: {}
    });
    
    dialogRef.afterClosed().subscribe(result => {
     
      if (result) {
        this.service.add(result).subscribe((condominium) => {
          
          this.getCondoms()
        this.dialog.open(ModalAlertDialogComponent, {
          width: '30em',
          data:{
            title: 'Condomínio cadastrado',
            message:'Deseja cadastrar usuários para o condomínio?',
            options:[
              {name: "Sim", value:true},
              {name:"Não", value: false}
            ]
          }
        }).afterClosed().toPromise().then((value)=>{
          if(value){
            this.dialog.open(ModalUsersComponent, {
              width: '50em',
              data:{
                condominium: condominium
              }
            })
          }else{
            
          }
        })
        });
      }


    });
  }

  edit(chg: Condominium) {
    const dialogRef = this.dialog.open(ModalCondominiumComponent, {
      width: '50em',
      data: { condo: chg, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       
        if(result === true){
          this.getCondoms();
        }else
        this.service.edit(result).subscribe(() => {
          this.getCondoms();
        });
      }
    });
  }

  deleteCondo(condo: Condominium) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      width: '400px',
      data: {
        type: 'question',
        message: 'Esta ação irá deletar condominio selecionado.<br/> Deseja continuar?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.del(condo.id).subscribe(() => {
          this.getCondoms();
        });
      } else {
        this.getCondoms();
      }
    });
  }
}
