import { VehicleTypeEnum } from './vehicle-type.enum';
import { ConnectorType } from './connector-type.enum';
import { Plug } from './plug';

export class VehicleModel {
  
  id?: number;
  
  brand?: string;
  
  model?: string
 
  type: VehicleTypeEnum
  
  name: string
  
  
  photo: string
  
  batteryCapacity?: string;
  
  createdAt?: Date;
  
  updatedAt?: Date;

  
  efficiency: number

  
  chargeRateDC: number
  
  
  chargeRateAC:number

 
  plugs: Plug[]
}
