import {Plug} from './plug'
import { Chargerstatus } from './chargerstatus.enum';
import { Charge } from './charge';
import { EVSE } from './evse';
import { Key } from './key';

export class Iconbox {
    id: number;
    name: string;
    lat:number
    lng:number
    address: string;
    evse: EVSE;
    isTurbo: boolean;
    isPrivate: boolean;
    price: string;
    meterId: string;
    voltage:number;
    mcmId: string
    power: string;
    maxCurrent: string;
    status: Chargerstatus;
    charges: Charge[]

    //additional
    keys:Key[]
    keyId:number
}