import { Plug } from "./plug"
import { Iconbox } from "./iconbox"

export class EVSE{

  
    id: number

    
    brand: string

    
    model: string

    
    serialNumber: string

    
    chargeMode: string

    image: string
    power: number

    
    currentAC: number

    
    currentDC: number

    
    phases: string

    
    chargeRateDC: number

    
    chargeRateAC: number

    
    voltage:number

    
    inputType: string

   
    ocpp: boolean
    

   
    
    deviceId: number

   
    device: Iconbox

   
    plugs: Plug[]

}