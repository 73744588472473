import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalChargePointComponent } from '../modal-charge-point/modal-charge-point.component';
import { ChargePointService } from 'src/app/services/charge-point.service';
import { ResponseData } from 'src/app/model/responsedata';
import { ChargePoint } from 'src/app/model/charge-point';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-charge-point',
  templateUrl: './charge-point.component.html',
  styleUrls: ['./charge-point.component.scss']
})
export class ChargePointComponent implements OnInit {


  responseData: ResponseData<ChargePoint>
  points: ChargePoint[] = []

  constructor(private dialog: MatDialog, private pointService: ChargePointService) { }

  ngOnInit() {
    this.findAllPoints()
  }


  add() {
    this.dialog.open(ModalChargePointComponent).afterClosed().subscribe((v)=>{
      if(v != null)this.findAllPoints()
    })
  }

  edit(point: ChargePoint) {
    this.dialog.open(ModalChargePointComponent, { data: point }).afterClosed().subscribe((v)=>{
      if(v != null)this.findAllPoints()
    })
  }

  findAllPoints() {
    this.pointService.findAll().subscribe((response: ResponseData<ChargePoint>) => {
      this.points = response.data
    })
  }

  deletePoint(point: ChargePoint) {
    this.pointService.delete(point.id).subscribe((response: ResponseData<ChargePoint>) => {

       this.dialog.open(AlertModalComponent,{
        data:{
          type:'alert',
          message:"Deseja remover esse ponto de acesso?"
        }

      }).afterClosed().subscribe((v)=>{
        if(v){
          this.findAllPoints()
        }
      })



    })
  }
}
