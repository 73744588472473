import { Component, OnInit, Inject } from '@angular/core';
import { Iconbox } from 'src/app/model/iconbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Plug } from 'src/app/model/plug';
import { ChargeService } from 'src/app/services/charge.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ModalEvseComponent } from '../modal-evse/modal-evse.component';
import { EvseService } from 'src/app/services/evse.service';
import { EVSE } from 'src/app/model/evse';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'app-modal-chargers',
  templateUrl: './modal-chargers.component.html',
  styleUrls: ['./modal-chargers.component.scss']
})
export class ModalChargersComponent implements OnInit {

  public publicData = { charge: null, action: null };
  public conn: Iconbox;
  public title = '';
  public evseList: EVSE[]
  public selectedEvseId : number
  

  isEdit:boolean 
  constructor(
    public dialogRef: MatDialogRef<ModalChargersComponent>,
    private service: ChargeService,
    private evseSevice: EvseService,
    private infoService: InfoService,
    private snack: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: any) {
    Object.assign(this.publicData, data);

    this.evseList = []
    if (data.action === 'edit') {

      this.isEdit = true;
      this.conn = data.charger;
      this.title = this.conn.name;
    
    } else {
      this.title = 'Novo Equipamento';
      this.conn = new Iconbox();
    
    }

   
  }

  onEvseSelected($event){
    console.log($event.target.value)
    this.selectedEvseId = $event.target.value;
    console.log(this.selectedEvseId)
  }

  ngOnInit(): void {
    this.getEvses()
  }

  async getEvses(){
     this.evseSevice.getAvailableEvse().subscribe((response)=>{
      this.evseList = response.data
    })
  }

 async unlinkEvse(){
    await this.evseSevice.unlinkEvse(this.conn.evse).toPromise();

    this.dialogRef.close()
  }

  linkEvse(){
    if(this.selectedEvseId == null){
      return this.infoService.showMessage("Selecione um EVSE")
    }
     this.evseSevice.linkEvse(this.selectedEvseId, this.conn).subscribe((iconbox)=>{
      this.conn = iconbox
    
     });
  }
 

  closeClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    
    var message = null;
    if(this.conn.name == null || this.conn.name.length == 0){
      message = "informe um nome"
    }
    //if( this.conn.plugs == null || this.conn.plugs.length == 0)message == "Escolha pelo menos um conector"
    else if(this.conn.price == null ||  this.conn.price.length == 0)message = "Informe o preço por KW/h"
    else if(this.conn.maxCurrent == null || this.conn.maxCurrent.length == 0)message ="Informe a corrente máxima"
    else if(this.conn.power == null || this.conn.power.length == 0)message ="Informe a potência"
    else if(this.conn.meterId == null || this.conn.meterId.length == 0)message ="Informe o número de série do METER"
    else if(this.conn.mcmId == null || this.conn.mcmId.length == 0)message ="Informe o número de série do MCM"
    
if(message == null)
    this.dialogRef.close(this.conn);
    else this.snack.open(message)
  }
}
