import { Injectable } from '@angular/core';
import { ResponseData } from '../model/responsedata';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Iconbox } from '../model/iconbox';
import { Chargerstatus } from '../model/chargerstatus.enum';
import { HttpClient } from '@angular/common/http';
import baseUrl from './util';
import { EvsePlug } from '../model/evse_plug';
import { Plug } from '../model/plug';
import { EVSE } from '../model/evse';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {
 

  constructor(private http: HttpClient) { }

  private getHeaders() {
    return {
      'x-access-token': localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
  }


  public getChargers(): Observable<ResponseData<Iconbox>> {
    return this.http.get(baseUrl+"/icombox")
      .pipe(map((response: ResponseData<Iconbox>) => response));
  }
  public getChargersByUser(id:number): Observable<Iconbox[]> {
    return this.http.get(baseUrl+"/icombox/profile/"+id)
      .pipe(map((response: Iconbox[]) =>response));
  }

  public addCharger(iot: Iconbox): Observable<Iconbox> {
    return this.http.post(baseUrl+"/icombox", iot)
      .pipe(map((response: Iconbox) => response));
  }



 
  //connectors

  ///********************************************** */
  public editCharger(iot: Iconbox): Observable<Iconbox> {
    return this.http.patch(baseUrl + '/icombox/'+iot.id, iot, )
      .pipe(map((response: Iconbox) => response));
  }

  public delCharger(id: number): Observable<boolean> {
    return this.http.delete(baseUrl + '/icombox/' + id, )
      .pipe(map((response: object) => response['message'] as boolean));
  }

 

}
