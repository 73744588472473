import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { UserService } from 'src/app/services/user.service';
import { Client } from 'src/app/model/client';
import { Profile } from 'src/app/model/profile';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {
  userList: Profile[];
  selectedOptionId: number;
  @Input() selected: Profile[];
  @Output() selection: EventEmitter<number> = new EventEmitter<number>();

  constructor(private service: UserService) { }

  ngOnInit() {
    console.log(this.selected)
    this.userList = [];
    if (isNullOrUndefined(this.selected)) {
      this.selected = [];
    }
    this.getUsers();
  }

  getUsers() {
    this.service.getUsers(1,200).subscribe(usrs => {
    var ids = this.selected.map((v)=>v.id);
      usrs.data.forEach((v)=>{
        if(!ids.includes(v.id))this.userList.push(v.profile)
      })
    });
  }

  filter(){
    return this.userList.filter((e)=> this.selected.filter((b)=>b.id == e.id).length == 0)
  }
  add() {
    //const users = this.userList.splice(this.selectedOption, 1);
    
    //this.selected.push(users[0]);
    this.selection.emit(this.selectedOptionId);
  }

  remove(index) {
    var user = this.selected[index]
    this.selection.emit(user.id);
    this.userList.push(user)
  }

}
