import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChargeService } from 'src/app/services/charge.service';
import { Iconbox } from 'src/app/model/iconbox';
import { isNullOrUndefined } from 'util';
import { Key } from 'src/app/model/key';

@Component({
  selector: 'app-key-select',
  templateUrl: './key-select.component.html',
  styleUrls: ['./key-select.component.scss']
})
export class KeySelectComponent implements OnInit {
  @Input() keyList: Key[];
  selectedOption: number;
 
  @Output() selection: EventEmitter<Key[]> = new EventEmitter<Key[]>();


  constructor(private service: ChargeService) {
  }

  ngOnInit() { 
  

  }
  

  add() {
    this.selection.emit([this.keyList[this.selectedOption]]);
  }


}
