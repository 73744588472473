import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConnectorService } from 'src/app/services/connector.service';
import { Plug } from 'src/app/model/plug';
import {
  AngularFireStorage,
} from 'angularfire2/storage';
import { isNullOrUndefined } from 'util';
import { ChargeService } from 'src/app/services/charge.service';
import { EvseService } from 'src/app/services/evse.service';

@Component({
  selector: 'app-connector-selection',
  templateUrl: './connector-selection.component.html',
  styleUrls: ['./connector-selection.component.scss']
})
export class ConnectorSelectionComponent implements OnInit {
  connectorList: Plug[];
  type:string
  @Input() selectedConnectors: Plug[];
  @Output() selection: EventEmitter<Plug> = new EventEmitter<Plug>();

  constructor(private connectorService: EvseService, private storage: AngularFireStorage) {
    this.type = 'CCS2 (IEC 62196)'

    console.log(this.selectedConnectors)
  }

  getConnectors() {
    
    this.connectorService.getPlugs().subscribe(async values => {

      this.connectorList = values.data;
    });
  }

  checkIsSelected(conn: Plug) {
    const filter = this.selectedConnectors.filter(connector => connector.id === conn.id);
    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  filter(list:Plug[]){
    return list.filter((p)=>p.type == this.type)
  }

  remove(){
    this.selectedConnectors = []
  }

  ngOnInit() {
    if (isNullOrUndefined(this.selectedConnectors)) {
      this.selectedConnectors = [];
    }
    this.getConnectors();
  }

  onClick(conn: Plug) {
    //const filter = this.selectedConnectors.filter(connector => connector.id === conn.id);
    // if (filter.length === 0) {
    //   this.selectedConnectors.push(conn);
    // } else {
    //   this.selectedConnectors.forEach((connector, index) => {
    //     if (connector.id === conn.id) {
    //       this.selectedConnectors.splice(index, 1);
    //     }
    //   });
    // }
    this.selection.emit(conn);

  }

}
