import { Condominium } from "./condominium"
import { Profile } from "./profile"

export class Address {



    id: number

    district: string
    street: string

    city: string

    country: string

    state: string

    number: string

    complement: string

    lat: number

    lng: number

    cep: string

    profileId: number



    condominiumId: number


    profile: Profile

    condominium: Condominium
}