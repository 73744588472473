import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/model/client';
import { Billingstatus } from 'src/app/model/billingstatus.enum';
import { UserService } from 'src/app/services/user.service';
import { Iconbox } from 'src/app/model/iconbox';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Profile } from 'src/app/model/profile';
import { AccessKey, Key } from 'src/app/model/key';
import * as uuid from 'uuid';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-modal-charge-key',
  templateUrl: './modal-charge-key.component.html',
  styleUrls: ['./modal-charge-key.component.scss']
})
export class ModalChargeKeyComponent implements OnInit {

  public title = '';

  public key: string
  public keyName: string


  keys: AccessKey[]


  constructor(
    public dialogRef: MatDialogRef<ModalChargeKeyComponent>,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: DashboardService) {


    this.title = "Chaves de Acesso";

  }

  ngOnInit(): void {
    this.getKeys()
  }

  closeClick(): void {
    this.dialogRef.close();
  }


  public getKeys() {
    this.service.getAccessKey().subscribe((value) => {

      this.keys = value
    })
  }

  public generate() {
    this.key = uuid.v4()
  }

  public updateKey(key: AccessKey) {
    key.active = !key.active
    this.service.updateAccessKey(key).subscribe((response) => {

      this.getKeys()
    }, (err) => {
      console.error(err);
    });
  }

  createKey(): void {
    if (this.key != null && this.key.length > 0) {
      this.service.createAccessKey({ name: this.keyName, value: this.key }).subscribe((response) => {

        this.key = "";
        this.keyName = ""
        this.getKeys()
      }, (err) => {
        console.error(err);
      });
    } else {
      this.snack.open("Insira ou gere uma chave")
    }
  }

  removeKey(key: AccessKey): void {
    this.service.removeAccessKey(key).subscribe((response) => {

      this.getKeys()
    }, (err) => {
      console.error(err);
    });
  }
}
