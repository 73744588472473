import { HttpErrorResponse } from '@angular/common/http';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { Buffer } from 'buffer';
import { map } from 'rxjs/operators';
import { EVSE } from 'src/app/model/evse';
import { Iconbox } from 'src/app/model/iconbox';
import { Plug } from 'src/app/model/plug';
import { ResponseError } from 'src/app/model/response_error';
import { ChargeService } from 'src/app/services/charge.service';
import { EvseService } from 'src/app/services/evse.service';
import { InfoService } from 'src/app/services/info.service';

@Component({
  selector: 'app-modal-evse',
  templateUrl: './modal-evse.component.html',
  styleUrls: ['./modal-evse.component.scss']
})
export class ModalEvseComponent implements OnInit {

  title: string = "Novo EVSE"
  device: Iconbox
  evse: EVSE
  isEdit: boolean
  private ref: AngularFireStorageReference;
  private task: AngularFireUploadTask;
  public uploadProgress: Observable<string>;
  public downloadURL: string;
  
  public uploading:boolean = false;

  constructor(
    private service: EvseService,
    private storage: AngularFireStorage,
    private info: InfoService,
    public dialogRef: MatDialogRef<ModalEvseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EVSE
  ) {
    this.title = "Novo EVSE"
    if (data == null) {

      this.evse = new EVSE()
      this.evse.plugs = []
      this.evse.chargeMode = '0'
    } else {
      this.isEdit = true
      this.evse = data
    }

  }

  ngOnInit() {


  }

  upload(event) {

    const randomId = "connector" + (new Date().getTime() * 10000);
    this.ref = this.storage.ref("/connectors/" + randomId);
    this.task = this.ref.put(event.target.files[0]);

    this.uploading = true;
    this.uploadProgress = null
    this.uploadProgress = this.task.percentageChanges().pipe(map((v) => v.toFixed(0)));
    this.task.then(async (file) => {
      this.evse.image = await file.ref.getDownloadURL()
      this.uploading = false;
      //this.conn.id = (isNullOrUndefined(this.conn.id) || this.conn.id.toString() === "") ? randomId : this.conn.id;

    });
  }

 

  async save() {

    if(this.evse.chargeMode == "0")return this.info.showMessage("Selecione um modo de carga")
    if (this.evse.plugs == null || this.evse.plugs.length == 0) {

      return this.info.showMessage("Selecione um conector")
    }

    this.evse.power = Number.parseFloat(this.evse.power.toString().replace(',','.'))
    this.evse.chargeRateAC = this.evse.chargeRateAC != null? Number.parseFloat(this.evse.chargeRateAC.toString().replace(',','.')):0.0
    this.evse.chargeRateDC = this.evse.chargeRateDC != null? Number.parseFloat(this.evse.chargeRateDC.toString().replace(',','.')):0.0
    this.evse.currentAC = this.evse.currentAC != null? Number.parseFloat(this.evse.currentAC.toString().replace(',','.')):0.0
    this.evse.currentDC = this.evse.currentDC != null? Number.parseFloat(this.evse.currentDC.toString().replace(',','.')):0.0
    this.evse.voltage = this.evse.voltage != null?  Number.parseFloat(this.evse.voltage.toString().replace(',','.')):0.0
    if (!this.isEdit) {
    
      this.service.saveEvse(this.evse).subscribe((e) => {
        this.dialogRef.close()
      }, (e:HttpErrorResponse) => {
        console.log(e)
        this.info.showMessage(e.error.errors[0].message)

      });
    }
    else {
      this.service.updateEvse(this.evse).subscribe((e) => {
        this.dialogRef.close()
      }, (e) => {
        console.log(e)
        this.info.showMessage(e.errors[0].message)

      });
    }


  }

  closeClick() {
    this.dialogRef.close()
  }

  changePlugs(connector: Plug) {


    
    this.evse.plugs= [connector]
   
    

  }
}
