import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Auth } from '../model/auth';
import { Profile } from '../model/profile';
import baseUrl from './util';

@Injectable()
export class AuthService {
  user: Observable<Profile>;
  private admin: boolean;

  constructor(private httpClient: HttpClient) {
    
  }

  login(email: string, password: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
     
      this.httpClient.post<Auth>(baseUrl+"/auth/admin/login",{
        email,
        password
      }, {
        headers:{
          'Access-Control-Allow-Origin':'*'
        }
      }).pipe().subscribe((auth)=>{

         localStorage.setItem('token', auth.token);
       
         resolve(true)
      },(e)=>{
        reject(e)
      })
      
      
     
    });
  }

  isLogged() : Promise<boolean>{
    return new Promise<boolean>((resolve, reject)=>{

   this.httpClient.get<Profile>(baseUrl+"/user/profile",).pipe().subscribe((profile)=>{
   
    resolve(true)

    },(e)=>{
      reject(false)
    })
  }) 
  }

  isAdmin() {
    return this.admin;
  }

  logout() {
    
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
    
  }

  forgotPass(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(true);
      // this.firebaseAuth.auth.sendPasswordResetEmail(email).then(() => {
      //   resolve(true);
      // }).catch(() => reject(false));
    });
  }

  newPassword(email: string, oldPass: string, newPass: string): Promise<any> {
    return new Promise((resolve, reject) => {
resolve(true);
      // this.firebaseAuth
      //   .auth
      //   .signInWithEmailAndPassword(email, oldPass)
      //   .then(value => {
      //     this.firebaseAuth.auth.currentUser.updatePassword(newPass).then(response => {
      //       resolve(response);
      //     }).catch(err => {
      //       reject(err);
      //     });
      //   }).catch(err => {
      //     reject(err);
      //   });
    });
  }
}
