import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Address } from 'src/app/model/address';
import { CepAddress } from 'src/app/model/cep_address';
import { ChargePoint } from 'src/app/model/charge-point';
import { ChargeStation } from 'src/app/model/charge-station';
import { Plug } from 'src/app/model/plug';
import { ResponseData } from 'src/app/model/responsedata';
import { ChargePointService } from 'src/app/services/charge-point.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { EvseService } from 'src/app/services/evse.service';

@Component({
  selector: 'app-modal-charge-point',
  templateUrl: './modal-charge-point.component.html',
  styleUrls: ['./modal-charge-point.component.scss']
})
export class ModalChargePointComponent implements OnInit {

  public plugs: Plug[]
  private ref: AngularFireStorageReference;
  private task: AngularFireUploadTask;
  public uploadProgress: Observable<string>;
  public downloadURL: string;
  public uploading: boolean = false

  public formGroup: FormGroup

  public point: ChargePoint
  isEdit: boolean = false
  public title: string
  constructor(
    private snackBar: MatSnackBar,
    private condService: CondominiumService,
    private pointService: ChargePointService,
    private evseService: EvseService,
    private afStorage: AngularFireStorage,
    public dialogRef: MatDialogRef<ModalChargePointComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    this.evseService.getPlugs().subscribe((data: ResponseData<Plug>)=>{
      this.plugs = data.data
      this.plugs = this.plugs.sort((a,b)=>(a.type + " | "+a.model).localeCompare((b.type + " | "+b.model)))
    })
    this.formGroup = new FormGroup({
      // power: new FormControl('', {
      //   validators: [
      //     Validators.required,
      //   ],
      // }),
      type: new FormControl('', { validators: [Validators.required] })
    })

    this.isEdit = this.data != null
    if (this.isEdit) {
      this.title = "Editar Ponto de Recarga"
      this.point = this.data
    } else {
      this.title = "Novo ponto de recarga"
      this.point = new ChargePoint()
      this.point.address = new Address()
      this.point.stations = []
    }

  }

  addStation(){
    if(this.formGroup.valid){
      this.pointService.addStation(this.point.id, {power: this.formGroup.value.power, type: this.formGroup.value.type}).subscribe((v)=>{
        this.point.stations.push(v)
      })
    }else{
      return this.snackBar.open('Preencha a potência e escolha o tipo', 'ok', { duration: 3000 })
    }
  }

  deleteStation(station:ChargeStation){
    this.pointService.removeStation(station.id).subscribe((v)=>{
      this.point.stations = this.point.stations.filter((f)=>f.id != station.id)
    })
  }

  upload(event) {

    const randomId = "connector" + (new Date().getTime() * 10000);
    this.ref = this.afStorage.ref("/connectors/" + randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploading = true
    this.uploadProgress = this.task.percentageChanges().pipe(map((v) => v.toFixed(0)));
    this.task.then(async (file) => {
      this.point.photo = await file.ref.getDownloadURL()
      this.uploading = false
      //this.conn.id = (isNullOrUndefined(this.conn.id) || this.conn.id.toString() === "") ? randomId : this.conn.id;

    });
  }


  save() {

    if (this.point.name == null || this.point.name.length == 0) {
      return this.snackBar.open('informe um nome', 'ok', { duration: 3000 })
    }

    if (this.point.address == null || this.point.address.lat == null) {
      return this.snackBar.open('informe o endereço', 'ok', { duration: 3000 })
    }
    this.pointService.createChargePoint(this.point).subscribe((v) => {
      this.dialogRef.close(true)
    })
  }

  update() {
    this.pointService.updateChargePoint(this.point).subscribe((v) => {
      this.dialogRef.close(true)
    })
  }

  closeClick() {
    this.dialogRef.close()
  }

  changeCep(e) {
    if (e.toString().replace('-', '').length == 8) {
      this.condService.getCep(e).subscribe((v: CepAddress) => {

        this.point.address.street = v.logradouro
        this.point.address.cep = v.cep
        this.point.address.city = v.localidade
        this.point.address.complement = v.complemento
        this.point.address.state = v.uf

        this.point.address.district = v.bairro
      })
    }
  }
}
