import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client } from '../model/client';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ResponseData } from '../model/responsedata';
import { Billingstatus } from '../model/billingstatus.enum';
import { Profile } from '../model/profile';
import baseUrl from './util';
import { User } from '../model/user';
import { Condominium } from '../model/condominium';
import { Key } from '../model/key';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _ENDPOINT = 'users';

  constructor(private http: HttpClient) { }

  private getHeaders() {
    return {
      'x-access-token': localStorage.getItem('token'),
      'Content-Type': 'application/json'
    };
  }

  public getUsers(page=1, size=20): Observable<ResponseData<User>> {
    return this.http.get(baseUrl+"/user/all?page="+page+"&size="+size)
      .pipe(map((v:ResponseData<User>)=>v));
  }

  public editUser(profile: Profile): Observable<any> {
    return this.http.patch(baseUrl + "/user/" + profile.id, profile)
      .pipe(map((response: any) => response));
  }

  public changeStatus(pUser: Profile, newStatus: Billingstatus): Observable<Client> {
    return this.http.patch(baseUrl + "+/admin/user/status", { id: pUser.id, billingStatus: newStatus })
      .pipe(map((response: any) => response));
  }

  public createUser(result: User, condominium?: Condominium, key?:Key): Observable<any> {
    return this.http.post(baseUrl + "/user", {...result,condominiumId: condominium != null? condominium.id:null, key: key.serialNumber != null? key: null})
      .pipe(map((v: Profile) => v));
  }


  // We never should del user.
  public delUser(id: number): Observable<any> {
    return this.http.delete(baseUrl + "/user/" + id)

      .pipe(map((response: any) => response));
  }

}
