import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VehicleModel } from 'src/app/model/vehicle_model';
import { ConnectorType } from 'src/app/model/connector-type.enum';
import { VehicleTypeEnum } from 'src/app/model/vehicle-type.enum';
import { ConnectorService } from 'src/app/services/connector.service';
import { Plug } from 'src/app/model/plug';
import { VehiclesService } from 'src/app/services/vehicles.service';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleBrand } from 'src/app/model/vehicle_brand';

@Component({
  selector: 'app-modal-vehicles',
  templateUrl: './modal-vehicles.component.html',
  styleUrls: ['./modal-vehicles.component.scss']
})
export class ModalVehiclesComponent {

  public publicData = { vehicle: null, action: null };
  public vehicle: VehicleModel;
  public title = '';
  public connectorList: any[];
  public typeList: any[];

  private ref: AngularFireStorageReference;
  private task: AngularFireUploadTask;
  public uploadProgress: Observable<string>;
  public downloadURL: string;

  brands:VehicleBrand[]

  public uploading: boolean = false;
  type = VehicleTypeEnum;

  isEdit:boolean
  constructor(
    private vehiclesService: VehiclesService,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<ModalVehiclesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    Object.assign(this.publicData, data);
    this.brands = []
    if (data.action === 'edit') {
      this.isEdit = true
      
      this.vehicle = JSON.parse(JSON.stringify(data.vehicle)); // shallow copy
      this.title = this.vehicle.name;
    } else {
      this.title = 'Novo Veículo';
      this.vehicle = new VehicleModel();
      this.vehicle.plugs = []
      this.vehicle.brand = 'none'
    }
    this.typeList = Object.entries(VehicleTypeEnum);
    this.fetchBrands()
  }

  async fetchBrands(){
    this.vehiclesService.getBrands().subscribe((data)=>{
      
      this.brands = data;
    
    });
  }


  changeConnectors(connector:Plug) {
    
    this.vehicle.plugs = [connector];
    
  }

  upload(event) {

    const randomId = "connector"+(new Date().getTime()*10000);
    this.ref = this.storage.ref("/connectors/"+randomId);
    this.task = this.ref.put(event.target.files[0]);
    this.uploading = true;
    this.uploadProgress = this.task.percentageChanges().pipe(map((v)=>v.toFixed(0)));
    this.task.then(async(file) => {
      this.vehicle.photo = await file.ref.getDownloadURL()
      this.uploading = false
      //this.conn.id = (isNullOrUndefined(this.conn.id) || this.conn.id.toString() === "") ? randomId : this.conn.id;
    
    });
  }


  closeClick(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.vehicle.batteryCapacity = this.vehicle.batteryCapacity.toString().replace(',','.')
    this.vehicle.efficiency = Number.parseFloat(this.vehicle.efficiency.toString().replace(',','.'))
    this.vehicle.chargeRateAC = Number.parseFloat(this.vehicle.chargeRateAC.toString().replace(',','.'))
    this.vehicle.chargeRateDC = Number.parseFloat(this.vehicle.chargeRateDC.toString().replace(',','.'))
    this.dialogRef.close(this.vehicle);
  }
}
