import { Component, OnInit } from '@angular/core';
import { Plug } from 'src/app/model/plug';
import { MatDialog } from '@angular/material/dialog';
import { ConnectorService } from 'src/app/services/connector.service';
import { AngularFireStorage } from 'angularfire2/storage';
import { ModalConnectorsComponent } from '../modal-connectors/modal-connectors.component';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { ChargeService } from 'src/app/services/charge.service';
import { EvseService } from 'src/app/services/evse.service';

@Component({
  selector: 'app-connector-list',
  templateUrl: './connector-list.component.html',
  styleUrls: ['./connector-list.component.scss']
})
export class ConnectorsListComponent implements OnInit {

  connectorList: Plug[];


  constructor(private service: EvseService, private dialog: MatDialog,
    private storage: AngularFireStorage) { }

  ngOnInit() {
    this.getConnectors();
  }

  getConnectors() {
    this.service.getPlugs().subscribe(async (values) => {
      this.connectorList = values.data;
      
      this.connectorList = this.connectorList.sort((a,b)=>a.model.localeCompare(b.model))
    });
  }

  edit(chg: Plug) {
    const dialogRef = this.dialog.open(ModalConnectorsComponent, {
      width: '50em',
      data: { conn: chg, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.updatePlug(result).subscribe(() => {
          this.getConnectors();
        });
      }
    });
  }

  add() {
    const dialogRef = this.dialog.open(ModalConnectorsComponent, {
      width: '50em',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.createPlug(result).subscribe((added) => {
          this.getConnectors();
        });
      }
    });
  }

  delete(conn: Plug) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      width: '400px',
      data: {
        type: 'question',
        message: 'Esta ação irá deletar o conector selecionado.<br/> Deseja continuar?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.removePlug(conn.id).subscribe(() => {
          this.getConnectors();
        });
      } else {
        this.getConnectors();
      }
    });
  }

}
