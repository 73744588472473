import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-modal-alert-dialog',
  templateUrl: './modal-alert-dialog.component.html',
  styleUrls: ['./modal-alert-dialog.component.scss']
})
export class ModalAlertDialogComponent implements OnInit {
selectedOption(value: any) {

  this.dialogRef.close(value)
}

  title:string
  message: string
  options:{name:string, value: any}[]
  constructor(
    private dialogRef: MatDialogRef<ModalAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data :{title: string, message: string, options: {name:string, value: any}[]}) { 
    this.title = data.title
    this.message= data.message
    this.options = data.options
  }

  ngOnInit() {
  }

}
