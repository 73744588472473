import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(
    private snak: MatSnackBar
  ) { }

  showMessage(message: string){
    this.snak.open(message,"OK",{duration:2000})
  }
}
