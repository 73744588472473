import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
   console.log("logged?")
    this.auth.isLogged().then(response => {
      console.log(response)
      if(response)
      this.router.navigate(['/admin/clients']);
    });
  }

}
