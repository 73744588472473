import { Injectable } from '@angular/core';
import { EVSE } from '../model/evse';
import { HttpClient } from '@angular/common/http';
import baseUrl from './util';
import { Iconbox } from '../model/iconbox';
import { map } from 'rxjs/operators';
import { ResponseData } from '../model/responsedata';
import { Observable } from 'rxjs';
import { EvsePlug } from '../model/evse_plug';
import { Plug } from '../model/plug';

@Injectable({
  providedIn: 'root'
})
export class EvseService {
 

  constructor(private http: HttpClient) { }

  saveEvse(evse: EVSE): Observable<EVSE> {
    return this.http.post(baseUrl+"/evse", evse)
    .pipe(map((response: EVSE) => response));
  }

  updateEvse(evse: EVSE): Observable<EVSE> {
    console.log(evse)
    return this.http.patch(baseUrl+"/evse/"+evse.id, evse)
    .pipe(map((response: EVSE) => response));
  }
  getEvses() :Observable<ResponseData<EVSE>> {
    return this.http.get(baseUrl+"/evse")
      .pipe(map((response: ResponseData<EVSE>) => response));
  }
  removeEvse(id: any) {
    return this.http.delete(baseUrl+"/evse/"+id)
    .pipe(map((response: any) => response));
  }
 
 

  getAvailableEvse():Observable<ResponseData<EVSE>> {
    return this.http.get(baseUrl+"/evse?available=true")
    .pipe(map((response: ResponseData<EVSE>) => response));
  }
 

  unlinkEvse(evse: EVSE):Observable<any> {
    return this.http.patch(baseUrl+"/evse/unlink",{
      evseId:evse.id,
    })
      .pipe(map((response: any) => response));
  }
  linkEvse(evse: number, iconbox: Iconbox):Observable<Iconbox> {
    return this.http.post(baseUrl+"/evse/link",{
      evseId:evse,
      deviceId: iconbox.id
    })
      .pipe(map((response: Iconbox) => response));
  }

  public addPlugEvse(cc: EvsePlug): Observable<Plug[]> {
   
    return this.http.post(baseUrl+"/evse/plug/link", cc).pipe(map((c)=>c as Plug[]));
  }

  public removePlugEvse(cc: EvsePlug): Observable<Plug[]> {
  
    return this.http.post(baseUrl+"/evse/plug/unlink", cc).pipe(map((c)=>c as Plug[]));
  }
  public getPlugs(): Observable<ResponseData<Plug>> {
    return this.http.get(baseUrl+"/evse/plug", )
      .pipe(map((response: ResponseData<Plug>) => response));
  }


  
  public createPlug(pConnector: Plug): Observable<Plug> {
    return this.http.post(baseUrl+"/evse/plug", pConnector,)
      .pipe(map((response: Plug) => response));
  }

  public updatePlug(pConnector: Plug): Observable<Plug> {
    return this.http.patch(baseUrl+"/evse/plug/"+pConnector.id, pConnector, )
      .pipe(map((response: Plug) => response));
  }

  public removePlug(vuid: number): Observable<boolean> {
    return this.http.delete(baseUrl+"/evse/plug/"+vuid,)
      .pipe(map((response: any) => response.data as boolean));
  }

}
