import { Component, OnInit } from '@angular/core';
import { VehiclesService } from 'src/app/services/vehicles.service';
import { VehicleModel } from 'src/app/model/vehicle_model';
import { ModalVehiclesComponent } from '../modal-vehicles/modal-vehicles.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AngularFireStorage } from 'angularfire2/storage';
import { VehicleTypeEnum } from 'src/app/model/vehicle-type.enum';
import { Pagination } from 'src/app/model/pagination';

@Component({

  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit {
  vehicleList: VehicleModel[];
  types: any = {}

  pagination: Pagination = new Pagination()
  constructor(private vehiclesService: VehiclesService, private dialog: MatDialog,
    private storage: AngularFireStorage) { }

  ngOnInit() {
    this.vehicleList = []
    this.getVehicles();
    Object.entries(VehicleTypeEnum).map((e) => {

      this.types[e[0]] = e[1]

    })


  }

  handlePageEvent(page:{pageIndex:number, pageSize: number}){

    this.pagination.page = page.pageIndex+1;
    this.pagination.size = page.pageSize

    console.log(this.pagination)
    this.getVehicles()
    
  }
  getVehicles() {
    this.vehiclesService.getVehicles(this.pagination.page, this.pagination.size).subscribe(async (response) => {
      this.vehicleList = response.data;
      this.pagination.page = response.page
      this.pagination.maxPage = response.maxPage
      this.pagination.total = response.total

      this.vehicleList.sort((a, b) => (a.brand != null ? a.brand : "").localeCompare(b.brand != null ? b.brand : ""))
    });
  }

  insertVehicles() {
    const dialogRef = this.dialog.open(ModalVehiclesComponent, {
      width: '50em',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vehiclesService.addVehicle(result).subscribe((added) => {
          this.getVehicles();
        });
      }
    });
  }

  deleteVehicle(vehicle: VehicleModel) {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      width: '400px',
      data: {
        type: 'question',
        message: 'Esta ação irá deletar veículo selecionado.<br/> Deseja continuar?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vehiclesService.delVehicle(vehicle.id).subscribe(() => {
          this.getVehicles();
        });
      } else { }
    });
  }

  editVehicles(pVehicle) {
    const dialogRef = this.dialog.open(ModalVehiclesComponent, {
      width: '50em',
      data: { vehicle: pVehicle, action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.vehiclesService.editVehicle(result).subscribe(() => {
          this.getVehicles();
        });
      }
    });
  }
}
