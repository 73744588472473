import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evse-page',
  templateUrl: './evse-page.component.html',
  styleUrls: ['./evse-page.component.scss']
})
export class EvsePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
