import { Profile } from "./profile"

export class PaymentAccount{
   
    id: number

    paymentDay:number

    mkup: number

    iss:number

    subscription:number

    type: string

    profileId:number
    profile:Profile
}