import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client } from '../model/client';
import { ResponseData } from '../model/responsedata';
import { map } from 'rxjs/operators';
import { Iconbox } from '../model/iconbox';
import { Condominium } from '../model/condominium';
import { Profile } from '../model/profile';
import baseUrl from './util';
import { AccessKey, Key } from '../model/key';
import { IconboxKey } from '../model/charger_key';
import { Billing } from './billing';
import { TermsPolicy } from '../model/terms_policy';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private _ENDPOINT = 'dashboard';

  constructor(private http: HttpClient) { }



  public getChargers(load:boolean): Observable<Iconbox[]> {
    return this.http.get(baseUrl+'/icombox',{
      headers:{
        "load":load == false? "false":"true"
      }
    } )
      .pipe(map((response: ResponseData<Iconbox>) => response.data as Iconbox[]));
  }


  public getUserHistory(profileId: number,year?:number): Observable<Billing[]> {
    var nowYear = new Date().getFullYear()
    return this.http.get(baseUrl+"/billing/profile/"+profileId+"?year="+(year == null? nowYear: year))
      .pipe(map((response: Billing[]) => response));
  }

  public createKey(key: Key): Observable<Key> {
    return this.http.post(baseUrl+"/user/key",key)
      .pipe(map((response: Key) => response));
  }
  public createAccessKey(key: AccessKey): Observable<AccessKey> {
    return this.http.post(baseUrl+"/admin/accesskey",key)
      .pipe(map((response: AccessKey) => response));
  }
  public updateAccessKey(key: AccessKey): Observable<AccessKey> {
    return this.http.post(baseUrl+"/admin/accesskey/change",key)
      .pipe(map((response: AccessKey) => response));
  }
  public updateKey(key: Key): Observable<Key> {
    return this.http.patch(baseUrl+"/admin/key",key)
      .pipe(map((response: Key) => response));
  }
  public removeAccessKey(key: AccessKey): Observable<any> {
    return this.http.delete(baseUrl+"/admin/accesskey?value="+key.value)
      .pipe(map((response: any) => response));
  }
  public getAccessKey(): Observable<AccessKey[]> {
    return this.http.get(baseUrl+"/admin/accesskey")
      .pipe(map((response: AccessKey[]) => response));
  }

  public removeKey(id: number): Observable<any> {
    return this.http.delete(baseUrl+"/user/key/"+id)
      .pipe(map((response: ResponseData<any>) => response));
  }

  public getKeysByProfile(id: number): Observable<Key[]> {
    return this.http.get(baseUrl+"/user/"+id+"/keys")
      .pipe(map((response: Key[]) => response));
  }
  public getTermsAndPolicy(): Observable<TermsPolicy> {
    return this.http.get(baseUrl+"/auth/terms")
      .pipe(map((response: TermsPolicy) => response));
  }
  public updateTermsAndPolicy(termsPolicy: TermsPolicy): Observable<any> {
    return this.http.post(baseUrl+"/auth/terms",termsPolicy)
      .pipe(map((response: any) => response));
  }

public connectKeyDevice(IconboxKey: IconboxKey):Observable<Iconbox>{

  return this.http.post(baseUrl+"/user/link", IconboxKey).pipe(map((response:Iconbox)=>response))

}
public disconnectKeyDevice(iconboxKey: IconboxKey):Observable<Iconbox>{

  return this.http.post(baseUrl+"/user/unlink", iconboxKey).pipe(map((response:Iconbox)=>response))

}
}