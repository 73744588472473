import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Client } from 'src/app/model/client';
import { VehiclesService } from 'src/app/services/vehicles.service';
import { VehicleModel } from 'src/app/model/vehicle_model';
import { MatDialog } from '@angular/material/dialog';
import { ModalUsersComponent } from 'src/app/components/modal-users/modal-users.component';
import { ModalVehiclesComponent } from 'src/app/components/modal-vehicles/modal-vehicles.component';
import { Observable } from 'rxjs';
import { Billingstatus } from 'src/app/model/billingstatus.enum';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ModalUserHistoryComponent } from '../modal-user-history/modal-user-history.component';
import { Profile } from 'src/app/model/profile';
import { ModalUserKeyComponent } from '../modal-user-key/modal-user-key.component';
import { MatSnackBar } from '@angular/material';
import { ResponseData } from 'src/app/model/responsedata';
import { User } from 'src/app/model/user';
import { ResponseError } from 'src/app/model/response_error';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalPaymentAccountComponent } from '../modal-payment-account/modal-payment-account.component';
import { Pagination } from 'src/app/model/pagination';
import { ModalAlertDialogComponent } from '../modal-alert-dialog/modal-alert-dialog.component';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  items: Observable<any[]>;
  clientList: Profile[];
  vehicleList: VehicleModel[];
  datasource: any;
  pagination: Pagination = new Pagination()
  @Input() dashboard: boolean;

  constructor(
    private snack: MatSnackBar,
    private userService: UserService,
    private dashboardService: DashboardService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {

    var subs = this.userService.getUsers(this.pagination.page, this.pagination.size).subscribe((response: ResponseData<User>) => {
     subs.unsubscribe()
      this.pagination.page = response.page
      this.pagination.maxPage = response.maxPage
      this.pagination.total = response.total

      this.clientList = response.data.map((e) => e.profile).sort((a, b) => a.name.localeCompare(b.name));


    });

  }

  openPaymentAccount(client: Profile) {

    const dialogRef = this.dialog.open(ModalPaymentAccountComponent, {
      width: '40em',
      data: { client: client }
    });

  }

  editUsers(data) {
    const dialogRef = this.dialog.open(ModalUsersComponent, {
      width: '40em',
      data: { client: data, type: 'update', action: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientList = [];
        this.userService.editUser(result).subscribe(() => {
          this.getUsers();
        }, (error) => {
          console.log(error);
          this.dialog.open(AlertModalComponent, {
            width: '400px',
            data: {
              type: 'alert',
              message: 'Erro para editar o usuário <br/>' + error.message
            }
          }).afterClosed().toPromise().then(() => {
            this.getUsers();
          });
        });
      }
    });
  }

  changeStatus(client: Profile) {

    this.userService.changeStatus(client, Billingstatus.active).subscribe(() => {
      this.getUsers();
    }, () => this.getUsers());

  }

  deleteAccount(client: Profile) {

    this.dialog.open(ModalAlertDialogComponent,{
      width:'30em',
      data:{
        message:'Deseja realmente deletar o usuário?',
        title:"Deletar usuário",
        options:[
          {name:"Sim", value:true},
          {name: "Não", value:false}
        ]
      }
    }).afterClosed().toPromise().then((value)=>{
      if(value){
        this.userService.delUser(client.userId).subscribe(() => {
          this.getUsers();
        }, () => this.snack.open("Não foi possível deletar o usuário"));
    
      }
    })

    
  }

  add() {
    const dialogRef = this.dialog.open(ModalUsersComponent, {
      width: '40em',
      data: { type: 'add', action: 'add' }
    });

    var sub = dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe()
      
      this.getUsers()
      // if (result  == null)
      //   return

        
      // this.clientList = [];

      // this.userService.createUser({ ...result, ...result.profile }).subscribe(
      //   () => this.getUsers(), 
      //   (http: HttpErrorResponse) => {
         
      //     var error: ResponseError = http.error
      //     var html = ''

      //     error.errors.forEach((e: any) => html += '<li>' + e.message + '</li>')

      //     this.dialog.open(AlertModalComponent, {
      //       width: '400px',
      //       data: {
      //         type: 'alert',
      //         message: 'Não foi possível criar o usuário <br/>' + html
      //       }
      //     }).afterClosed().toPromise().then(() => this.getUsers() );
      // });
    });
  }

  handlePageEvent(page:{pageIndex:number, pageSize: number}){

    this.pagination.page = page.pageIndex+1;
    this.pagination.size = page.pageSize
    this.getUsers()
    
  }
  viewCharges(client: Profile) {

    const dialogRef = this.dialog.open(ModalUserHistoryComponent, {
      width: '50em',
      data: { user: client }
    });

    // } else {
    //   this.userService.getBills(client).subscribe((response) => {
    //     console.info(response);

    //     const dialogRef = this.dialog.open(ModalUserHistoryComponent, {
    //       width: '50em',
    //       data: { history: response, user: client }
    //     });
    //   });
    // }
  }


  showKeys(client: Profile) {
    ;// if (this.dashboard) {
    const dialogRef = this.dialog.open(ModalUserKeyComponent, {
      width: '30em',
      data: { history: [], user: client }
    });
    // } else {
    //   this.userService.getBills(client).subscribe((response) => {
    //     console.info(response);

    //     const dialogRef = this.dialog.open(ModalUserHistoryComponent, {
    //       width: '50em',
    //       data: { history: response, user: client }
    //     });
    //   });
    // }
  }

}
