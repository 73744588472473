import { Component, OnInit, Inject } from '@angular/core';
import { Condominium } from 'src/app/model/condominium';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as admin from 'firebase';
import { Iconbox } from 'src/app/model/iconbox';
import { Client } from 'src/app/model/client';
import { Profile } from 'src/app/model/profile';
import { User } from 'src/app/model/user';
import { ChargeService } from 'src/app/services/charge.service';
import { CondominiumService } from 'src/app/services/condominium.service';
import { Address } from 'src/app/model/address';
import { CepAddress } from 'src/app/model/cep_address';

@Component({
  selector: 'app-modal-condominium',
  templateUrl: './modal-condominium.component.html',
  styleUrls: ['./modal-condominium.component.scss']
})
export class ModalCondominiumComponent implements OnInit {

  public publicData = { condo: null, action: null };
  public condo: Condominium;
  public title = 'Novo Condomínio';


  selectedChargers: Iconbox[];
  edited: Boolean

  selectedProfiles: Profile[];

  isEdit: boolean

  constructor(
    private condService: CondominiumService,
    public dialogRef: MatDialogRef<ModalCondominiumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    Object.assign(this.publicData, data);

    this.edited = false;

    this.selectedChargers = []
    this.selectedProfiles = []

    if (data.action === 'edit') {
      this.isEdit = true;
      this.condo = data.condo;

      this.condo.profiles.forEach((e) => this.selectedProfiles.push(e))
      this.condo.devices.forEach((e) => this.selectedChargers.push(e))


    } else {
      this.isEdit = false;
      this.condo = new Condominium();
      this.condo.address = new Address()
      this.condo.responsable = "";
      this.condo.email = ""
      this.condo.phone = ""

    }


  }

  ngOnInit() {


  }

  closeClick(): void {
    this.dialogRef.close(this.edited);
  }

  chargersChange(charges: Iconbox[]) {

    if (charges.length > 0) {

      var charge = charges[0]
      this.edited = true;
      var contains = this.selectedChargers.filter((v) => v.id == charge.id).length == 1
      if (contains) {

        this.condService.removeCharger({ condominiumId: this.condo.id, deviceId: charge.id }).subscribe((val) => {
          if (val)
            this.selectedChargers = val;


        })
      } else {

        this.condService.addCharger({ condominiumId: this.condo.id, deviceId: charge.id }).subscribe((val) => {
          if (val)
            this.selectedChargers = val;
        })
      }

    }
  }

  usersChange(profileId: number) {

    if (profileId != null) {

      this.edited = true

      var contains = this.selectedProfiles.filter((v) => v.id == profileId).length == 1
      if (contains) {

        this.condService.removeProfile({ condominiumId: this.condo.id, profileId: profileId }).subscribe((val) => {


          if (val)
            this.selectedProfiles = val;

        })
      } else {


        this.condService.addProfile(this.condo.id, profileId).subscribe((val) => {

          if (val)
            this.selectedProfiles = val;
        })
      }
    }

  }

  save(): void {

    this.dialogRef.close(this.condo);
  }

  changeCep(e) {
    if (e.toString().replace('-', '').length == 8) {
      this.condService.getCep(e).subscribe((v: CepAddress) => {

        this.condo.address.street = v.logradouro
        this.condo.address.cep = v.cep
        this.condo.address.city = v.localidade
        this.condo.address.complement = v.complemento
        this.condo.address.state = v.uf

        this.condo.address.district = v.bairro
      })
    }
  }

}
