import { BrowserModule } from '@angular/platform-browser';
import {  CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from 'angularfire2';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// SERVICES
import { AuthService } from './services/auth.service';
import { LoginpageComponent } from './pages/loginpage/loginpage.component';
import { HomeComponent } from './pages/admin/home/home.component';
import { LoadingComponent } from './components/loading/loading.component';

import { LoaderService } from './services/loader.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoaderInterceptor } from './services/loader.interceptor';
import { TopbarComponent } from './components/topbar/topbar.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ModalUsersComponent } from './components/modal-users/modal-users.component';
import { ModalVehiclesComponent } from './components/modal-vehicles/modal-vehicles.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'node_modules/ngx-mask/';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { PerformanceComponent } from './components/performance/performance.component';
import { ModalChargersComponent } from './components/modal-chargers/modal-chargers.component';
import { ChargeListComponent } from './components/charge-list/charge-list.component';
import { MinCardsComponent } from './components/min-cards/min-cards.component';
import { VehicleListComponent } from './components/vehicle-list/vehicle-list.component';
import { ConnectorsListComponent } from './components/connector-list/connector-list.component';
import { CellphonePipe } from './cellphone.pipe';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { ClientPageComponent } from './pages/admin/client-page/client-page.component';
import { VehiclePageComponent } from './pages/admin/vehicle-page/vehicle-page.component';
import { ConnectorPageComponent } from './pages/admin/connector-page/connector-page.component';
import { ModalConnectorsComponent } from './components/modal-connectors/modal-connectors.component';
import { ChargerPagesComponent } from './pages/admin/charger-pages/charger-pages.component';
import { CondominumPageComponent } from './pages/admin/condominum-page/condominum-page.component';
import { ConnectorSelectionComponent } from './components/connector-selection/connector-selection.component';
import { AngularFireStorage, AngularFireStorageModule } from 'angularfire2/storage';
import { CondominiumListComponent } from './components/condominium-list/condominium-list.component';
import { UserDashboardComponent } from './pages/dashboard/user-dashboard/user-dashboard.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthUserGuardService } from './services/auth-user-guard.service';
import { ModalCondominiumComponent } from './components/modal-condominium/modal-condominium.component';
import { AddressCompleterComponent } from './components/address-completer/address-completer.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { ChargerSelectComponent } from './components/charger-select/charger-select.component';
import { ConsumDataChartComponent } from './components/consum-data-chart/consum-data-chart.component';


import { NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ModalResetPasswordComponent } from './components/modal-reset-password/modal-reset-password.component';
import { ProfilePageComponent } from './pages/dashboard/profile-page/profile-page.component';
import { DashboardDataComponent } from './pages/dashboard/dashboard-data/dashboard-data.component';
import { ModalPasswordChangeComponent } from './components/modal-password-change/modal-password-change.component';
import { ModalUserHistoryComponent } from './components/modal-user-history/modal-user-history.component';
import { BasicAuthInterceptor } from './services/auth-intercept';
import { ModalUserKeyComponent } from './components/modal-user-key/modal-user-key.component';
import { KeySelectComponent } from './components/key-select/key-select.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule, MatPaginatorModule } from '@angular/material';
import { ModalChargeKeyComponent } from './components/modal-charge-key/modal-charge-key.component';
import { TermsPolicyComponent } from './terms-policy/terms-policy.component';
import { ModalEvseComponent } from './components/modal-evse/modal-evse.component';
import { EvseListComponent } from './evse-list/evse-list.component';
import { EvsePageComponent } from './pages/admin/evse-page/evse-page.component';
import { ModalPaymentAccountComponent } from './components/modal-payment-account/modal-payment-account.component';
import { ChargePointComponent } from './components/charge-point/charge-point.component';
import { ModalChargePointComponent } from './components/modal-charge-point/modal-charge-point.component';
import { ModalAlertDialogComponent } from './components/modal-alert-dialog/modal-alert-dialog.component';
// const config: SocketIoConfig = { url: 'http://localhost:8088', options: {
//   // "force new connection": true,
//   // "reconnectionAttempts": "Infinity",
//   // "timeout": 10000,
//   "transports": ["websocket",'polling'],
//   //"secure":true,
// } };
@NgModule({
  declarations: [
    AppComponent,
    LoginpageComponent,
    HomeComponent,
    LoadingComponent,
    TopbarComponent,
    UsersListComponent,
    ModalUsersComponent,
    ModalVehiclesComponent,
    ModalConnectorsComponent,
    AlertModalComponent,
    PerformanceComponent,
    ModalChargersComponent,
    ChargeListComponent,
    MinCardsComponent,
    VehicleListComponent,
    CellphonePipe,
    LeftMenuComponent,
    ClientPageComponent,
    VehiclePageComponent,
    ConnectorPageComponent,
    ConnectorsListComponent,
    ModalConnectorsComponent,
    ChargerPagesComponent,
    CondominumPageComponent,
    ConnectorSelectionComponent,
    CondominiumListComponent,
    UserDashboardComponent,
    ModalCondominiumComponent,
    AddressCompleterComponent,
    UserSelectComponent,
    KeySelectComponent,
    ChargerSelectComponent,
    ConsumDataChartComponent,
    ModalResetPasswordComponent,
    ProfilePageComponent,
    DashboardDataComponent,
    ModalPasswordChangeComponent,
    ModalUserHistoryComponent,
    ModalUserKeyComponent,
    ModalEvseComponent,
   ModalChargeKeyComponent,
   TermsPolicyComponent,
   ModalEvseComponent,
   EvseListComponent,
   EvsePageComponent,
   ModalPaymentAccountComponent,
   ChargePointComponent,
   ModalChargePointComponent,
   ModalAlertDialogComponent

  ],
  imports: [
    MatSnackBarModule,

    MDBBootstrapModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatExpansionModule,

    MatInputModule,
    NgxMaskModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    //SocketIoModule.forRoot(config),
  ],
  entryComponents: [
    ModalUsersComponent,
    ModalVehiclesComponent,
    ModalChargersComponent,
    ModalCondominiumComponent,
    ModalConnectorsComponent,
    ModalResetPasswordComponent,
    ModalPasswordChangeComponent,
    ModalUserKeyComponent,
    ModalUserHistoryComponent,
    ModalChargeKeyComponent,
    ModalEvseComponent,
    ModalPaymentAccountComponent,
    ModalChargePointComponent,
    AlertModalComponent,
    ModalAlertDialogComponent
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  providers: [

    AuthService, LoaderService, AngularFireStorage,
    AuthGuardService, AuthUserGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {

}
